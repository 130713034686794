import { environment } from '../../environment';

export interface SearchResult {
  url: string;
  title: string;
  highlights: string[];
}

export interface AggregationRecord {
  count: number;
  key: string;
  tKey: string;
}
export interface CountResult {
  categories: AggregationRecord[];
  domains: AggregationRecord[];
}

export const searchApi = {
  search: async ({
    query,
    domain,
    category,
    locale,
    perPage,
  }: {
    query: string;
    domain?: string;
    category?: string;
    locale?: string;
    perPage?: number;
  }) => {
    try {
      let queryParams = `query=${query}&locale=${locale || 'en'}&perPage=${
        perPage || 8
      }`;
      if (domain) {
        queryParams = queryParams.concat(`&domain=${domain}`);
      }
      if (category) {
        queryParams = queryParams.concat(`&category=${category}`);
      }

      const response = await fetch(
        `${environment.globalSearchApiEndpoint}/search?${queryParams}`,
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: Array<SearchResult> = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching search results:', error);
      throw error;
    }
  },
  count: async (query: string, locale = 'en') => {
    try {
      const response = await fetch(
        `${environment.globalSearchApiEndpoint}/search/counts?query=${query}&locale=${locale}`,
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: CountResult = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching search results:', error);
      throw error;
    }
  },
};
