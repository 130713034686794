import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CountResult, searchApi, SearchResult } from './search.api';

// Custom Hook for Fetching Results
export const useSearchResults = (
  searchQuery: string,
  domainValue: string | undefined,
  categoryValue: string | undefined,
) => {
  const { i18n } = useTranslation();
  const [results, setResults] = useState<SearchResult[]>([]);
  const [counts, setCounts] = useState<CountResult>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handler = setTimeout(async () => {
      if (searchQuery) {
        setIsLoading(true);
        const results = await searchApi.search({
          query: searchQuery,
          domain: domainValue,
          category: categoryValue,
          locale: i18n.language,
        });
        const counts = await searchApi.count(searchQuery, i18n.language);
        setResults(results);
        setCounts(counts);
        setIsLoading(false);
      } else {
        setResults([]);
      }
    }, 300); // debounce delay of 300ms

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery, domainValue, categoryValue, i18n.language]);

  return { results, counts, isLoading };
};
