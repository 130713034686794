import Card, { CardContent } from '@grimme/ui-components/card';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CountResult, SearchResult } from './search.api';
import { useSearchResults } from './use-search-results.hook';

const Filter: React.FC<{
  data: CountResult | undefined;
  onCategoryClick: (value: string | undefined) => void;
  onDomainClick: (value: string | undefined) => void;
  selectedCategory?: string;
  selectedDomain?: string;
}> = ({
  data,
  onCategoryClick,
  onDomainClick,
  selectedCategory,
  selectedDomain,
}) => {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  const filters = [
    {
      title: t('myGRIMME_global_search_label_categories', 'Categories'),
      items: data.categories,
      onClick: (key: string) =>
        onCategoryClick(selectedCategory === key ? undefined : key),
      selected: selectedCategory,
    },
    {
      title: t('myGRIMME_global_search_label_domain', 'Domains'),
      items: data.domains,
      onClick: onDomainClick,
      selected: selectedDomain,
    },
  ];

  return (
    <div className="space-y-6">
      {filters.map(({ title, items, selected, onClick }) => (
        <div className="max-w-[360px]" key={title}>
          <h3 className="text-sm text-primary uppercase mb-2">{title}</h3>
          <ul className="space-y-1">
            {items.map((item) => (
              <li
                key={item.key}
                className={`flex justify-between items-center cursor-pointer p-1 rounded hover:bg-grey-100 ${
                  selected === item.key ? 'font-semibold' : ''
                } overflow-hidden`}
                onClick={() => onClick(item.key)}
              >
                <span className="flex-1 truncate whitespace-nowrap overflow-hidden">
                  {`${t(item.tKey, item.key)}`}
                </span>
                <span className="w-8 text-right">{item.count}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

const ResultsList: React.FC<{
  results: SearchResult[];
}> = ({ results }) => {
  const { t } = useTranslation();

  return (
    <>
      <h3 className="text-primary text-sm uppercase mb-2">
        {t('myGRIMME_global_search_popup_top_results', 'Top Results')}
      </h3>
      {results.map((result) =>
        result.highlights.map((highlight, index) => (
          <Card key={`search-result-${String(result.url)}-${index}`}>
            <CardContent>
              <a
                href={result.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <p className="text-overline mb-1.5 text-primary">
                  {result.title}
                </p>
                <h4>{result.title}</h4>
                <div dangerouslySetInnerHTML={{ __html: highlight }} />
              </a>
            </CardContent>
          </Card>
        )),
      )}
    </>
  );
};

export const SearchResults = ({ searchQuery }: { searchQuery: string }) => {
  const { t } = useTranslation();

  const [domainFilter, setDomainFilter] = useState<string>();
  const [categoryFilter, setCategoryFilter] = useState<string>();

  const { results, counts, isLoading } = useSearchResults(
    searchQuery,
    domainFilter,
    categoryFilter,
  );

  const noResults = !isLoading && Boolean(searchQuery) && results.length === 0;

  return (
    <section className="py-4 sm:py-8 px-4 sm:px-0">
      <div className="flex flex-col sm:flex-row-reverse gap-8 sm:gap-20">
        {/* Filters: Display above results on mobile, right on desktop */}
        <div className="grid text-left sm:w-1/3">
          <Filter
            data={counts}
            selectedDomain={domainFilter}
            selectedCategory={categoryFilter}
            onCategoryClick={(value) => {
              setCategoryFilter(value);
              setDomainFilter(undefined);
            }}
            onDomainClick={(value) => {
              setDomainFilter(value);
              setCategoryFilter(undefined);
            }}
          />
        </div>

        {/* Search Results */}
        <div className="grid gap-4 text-left sm:w-2/3">
          {noResults ? (
            <p className="text-left">
              {t(
                'myGRIMME_global_search_popup_no_results',
                'No results found. Try something else.',
              )}
            </p>
          ) : (
            <ResultsList results={results} />
          )}
        </div>
      </div>
    </section>
  );
};
