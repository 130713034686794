import { faChevronRight, IconLookup } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@grimme/ui-components/button';
import Separator from '@grimme/ui-components/separator';
import classNames from 'classnames';
import Link from 'next/link';
import { useState } from 'react';
import { ButterImageV2 } from '../butter-image-v2';
import { GlobalSearchDialog } from '../global-search';
import { VideoComponent } from './video-component';

export type HeroProps = {
  background_image: string;
  background_video_hq: string;
  background_video_lq: string;
  sections: {
    background_color: string;
    image: string;
    label_icon: string;
    label_icon_type: string;
    label_text: string;
    logo?: string;
    main_button_link: string;
    main_button_text: string;
    main_button_variant?: string;
    secondary_button_link?: string;
    secondary_button_text?: string;
    secondary_button_variant?: string;
    subtitle: string;
    text_color: string;
    title: string;
  }[];
  subtitle: string;
  title: string;
};

type ButtonVariant =
  | 'text'
  | 'primary'
  | 'outline'
  | 'outline-negative'
  | 'glass';

export function Hero(props: HeroProps) {
  const { title, subtitle, background_image, background_video_hq, sections } =
    props;

  const [activeSection, setActiveSection] = useState<string | undefined>(
    undefined,
  );

  return (
    <div onMouseLeave={() => setActiveSection(undefined)}>
      <div className="relative bg-off-white flex min-h-[616px] max-h-[616px] lg:min-h-[634px] lg:max-h-[634px]">
        {/* Default section */}
        <section
          className={classNames(
            'flex flex-1 items-center justify-center text-white opacity-100 transition-opacity duration-300',
            { 'md:opacity-0': !!activeSection },
          )}
        >
          <div className="absolute inset-0">
            <ButterImageV2
              fill
              priority
              alt=""
              className="object-cover"
              src={background_image}
            />
            <VideoComponent url={background_video_hq} />
            <div
              className="absolute inset-0"
              style={{
                background:
                  'linear-gradient(180deg, transparent 0%, black 100%)',
              }}
            />
          </div>

          <div className="flex flex-1 flex-col justify-end md:justify-center w-full h-full max-w-[1200px] px-6 pb-[84px] md:pb-0 gap-6 z-10">
            <article className="flex flex-col gap-4">
              <h1>{title}</h1>
              <h5>{subtitle}</h5>
            </article>
            <Separator thickness="thicker" className="g-max-w-[40px]" />
            <GlobalSearchDialog />
          </div>
        </section>
        {/* Dynamic sections */}
        {sections.map((section) => {
          const hasSecondaryButton = section.secondary_button_link;

          return (
            <section
              className={classNames(
                'hidden absolute inset-0 md:flex md:flex-row transition-opacity duration-700 opacity-0',
                { '!opacity-100 z-[1]': section.label_text === activeSection },
              )}
              key={section.label_text}
            >
              <div
                className={classNames(
                  `relative flex flex-1 items-center`,
                  'md:p-11 md:pl-6 md:justify-end md:flex-none md:min-w-[calc(50vw_-_95px)] md:max-w-[55vw]',
                  'p-6 pb-12 justify-stretch',
                )}
                style={{ backgroundColor: `#${section.background_color}` }}
              >
                <article
                  className="relative flex flex-col gap-6 md:max-w-[430px]"
                  style={{ color: `#${section.text_color}` }}
                >
                  {section.logo && (
                    <div className="md:block hidden absolute -top-[55px] -right-5 h-[30px] w-20">
                      <ButterImageV2
                        fill
                        priority
                        alt=""
                        className="object-cover"
                        src={section.logo}
                      />
                    </div>
                  )}
                  <div className="flex flex-col gap-4">
                    <h6>{section.label_text}</h6>
                    <h3>{section.title}</h3>
                    <p className="g-typography-body2">{section.subtitle}</p>
                  </div>
                  <div className="flex flex-wrap gap-4">
                    <Button
                      asChild
                      className={classNames('text-nowrap flex-1', {
                        'md:!flex-none': !hasSecondaryButton,
                      })}
                      variant={
                        (section.main_button_variant as
                          | ButtonVariant
                          | undefined) || 'primary'
                      }
                    >
                      <Link href={section.main_button_link}>
                        {section.main_button_text}
                      </Link>
                    </Button>
                    {hasSecondaryButton && (
                      <Button
                        asChild
                        className="flex-1 text-nowrap"
                        variant={
                          (section.secondary_button_variant as
                            | ButtonVariant
                            | undefined) || 'outline'
                        }
                      >
                        <Link href={section.secondary_button_link!}>
                          {section.secondary_button_text}
                        </Link>
                      </Button>
                    )}
                  </div>
                </article>
              </div>
              <div className="relative flex-1">
                <ButterImageV2
                  fill
                  priority
                  alt=""
                  className="object-cover"
                  src={section.image}
                />
              </div>
            </section>
          );
        })}
      </div>
      {/* Selector */}
      <div className="relative flex justify-center md:-mt-[34px] z-10">
        {/* Mobile */}
        <div className="flex md:hidden flex-1  bg-[black] pb-6 px-6">
          <ul className="flex flex-1 flex-col gap-2">
            {sections.map((section) => (
              <Link href={section.main_button_link} key={section.label_text}>
                <li
                  className="flex items-center justify-between px-6 min-h-[52px] rounded text-grey-500 bg-white"
                  key={section.label_text}
                >
                  <div className="flex items-center gap-3">
                    <FontAwesomeIcon
                      height={16}
                      icon={
                        {
                          iconName: section.label_icon,
                          prefix: section.label_icon_type,
                        } as IconLookup
                      }
                      width={16}
                    />
                    <p className="g-typography-overline">
                      {section.label_text}
                    </p>
                  </div>
                  <FontAwesomeIcon
                    className="w-[10px] h-3"
                    height={10}
                    icon={faChevronRight}
                    width={12}
                  />
                </li>
              </Link>
            ))}
          </ul>
        </div>
        {/* Desktop */}
        <div className="hidden md:flex flex-1 min-h-[68px] max-w-[1200px] px-6">
          <div className="flex flex-1 rounded bg-white gap-1 g-shadow-sm">
            {sections.map((section) => (
              <Link
                className={classNames(
                  'flex flex-1 flex-col gap-2 py-2 justify-center items-center text-grey-500 cursor-pointer',
                  {
                    'text-primary border-b-2 border-primary':
                      activeSection === section.label_text,
                  },
                )}
                href={section.main_button_link}
                key={`landing-hero-section-${section.label_text}`}
                onMouseEnter={() => setActiveSection(section.label_text)}
              >
                <FontAwesomeIcon
                  height={16}
                  icon={
                    {
                      iconName: section.label_icon,
                      prefix: section.label_icon_type,
                    } as IconLookup
                  }
                  width={16}
                />
                <p className="g-typography-overline text-center line-clamp-2">
                  {section.label_text}
                </p>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
