import { useCallback, useEffect, useState } from 'react';
import * as PrimitiveDialog from '@radix-ui/react-dialog';
import { useTranslation } from 'react-i18next';
import { cn } from '@/utils';
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Container from '@grimme/ui-components/container';
import SearchInput from '@grimme/ui-components/search-input';
import { SearchResults } from './search-results';

export const useDebounce = <T,>(value: T, delay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const GlobalSearchDialog = () => {
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const { t } = useTranslation();

  const debouncedSearchInput = useDebounce(searchInput, 300);

  const handleCloseDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <PrimitiveDialog.Root onOpenChange={() => setOpen(!open)} open={open}>
      <PrimitiveDialog.Trigger asChild>
        <div
          className="flex max-h-10 items-center justify-between overflow-hidden rounded border-[1px] border-grey-300 py-2 pl-4 pr-[14px] w-full"
          role="button"
        >
          <p className="mr-2 overflow-hidden text-ellipsis whitespace-nowrap text-left text-grey-300">
            {t('myGRIMME_global_search_popup_title', 'Find Everything GRIMME')}
          </p>
          <FontAwesomeIcon
            className="h-5 w-5 text-white-500"
            icon={faMagnifyingGlass}
          />
        </div>
      </PrimitiveDialog.Trigger>
      <PrimitiveDialog.Portal>
        <PrimitiveDialog.Overlay />
        <PrimitiveDialog.Content
          className={cn(
            'fixed inset-0 z-[10000] h-dvh min-h-dvh w-full bg-white pt-14 focus:outline-none data-[state=open]:animate-fadeIn sm:pt-24',
            {
              'overflow-y-auto': debouncedSearchInput?.length > 1,
            },
            {
              'overflow-y-hidden': debouncedSearchInput?.length <= 1,
            },
          )}
        >
          <Container className="relative flex flex-col gap-4">
            <PrimitiveDialog.Close asChild aria-label="close">
              <button
                className="fixed top-3 z-10 inline-flex h-10 w-10 appearance-none items-center justify-center self-end rounded-full bg-off-white text-grey-500 hover:bg-grey-100 focus:outline-none sm:top-10 sm:h-12 sm:w-12 xl:h-14 xl:w-14"
                onClick={handleCloseDialog}
              >
                <FontAwesomeIcon icon={faXmark} size="lg" />
              </button>
            </PrimitiveDialog.Close>
            <PrimitiveDialog.Title asChild>
              <h2>
                {t(
                  'myGRIMME_global_search_popup_title',
                  'Find Everything GRIMME',
                )}
              </h2>
            </PrimitiveDialog.Title>
            <PrimitiveDialog.Description asChild>
              <p className="text-balance">
                {t(
                  'myGRIMME_global_search_popup_subtitle',
                  'Use our search to quickly find news, used machines, machine models, and more.',
                )}
              </p>
            </PrimitiveDialog.Description>
            <div className="z-[1] mt-4 w-full self-end transition-all duration-500 ease-in-out sm:mt-10 [&>div]:w-full">
              <SearchInput
                autoFocus={true}
                className="w-full"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                label={t(
                  'myGRIMME_global_search_popup_placeholder',
                  'Search for machines, news, and more...',
                )}
                size="md"
              />
            </div>
            <div
              className={cn(
                'w-full bg-white transition-all duration-500 ease-in-out [&>*]:transition-all [&>*]:duration-500 [&>*]:ease-in-out',
                {
                  'max-h-0 [&>*]:invisible [&>*]:opacity-0':
                    searchInput?.length <= 1,
                },
                {
                  'pb-10 pt-6 md:pb-14 md:pt-10 [&>*]:visible [&>*]:opacity-100':
                    searchInput?.length > 1,
                },
              )}
            >
              <SearchResults searchQuery={debouncedSearchInput} />
            </div>
          </Container>
        </PrimitiveDialog.Content>
      </PrimitiveDialog.Portal>
    </PrimitiveDialog.Root>
  );
};
